import { useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import type {
  InternalHost,
  Xinglet,
  XingletComponent,
} from '@xing-com/crate-xinglet';

export interface XingletProps {
  children?: ReactNode;
  [name: string]: unknown;
}

export interface XingletContainerProps extends XingletProps {
  component: XingletComponent;
  name: string;
  host: InternalHost;
  xinglet: Xinglet;
}

const mountedXinglets = new Set<string>();

export const XingletContainer: FC<XingletContainerProps> = ({
  name,
  host,
  xinglet,
  component: XingletComponent,
  ...props
}) => {
  host.runtime.events.emit('xinglet-rendered', name);

  useEffect(() => {
    if (xinglet.singleton && mountedXinglets.has(name)) {
      throw new Error(
        'Cannot mount a Xinglet that is defined as a singleton more than once'
      );
    }

    mountedXinglets.add(name);

    host.runtime.events.emit('xinglet-mounted', name);

    return () => {
      mountedXinglets.delete(name);
    };
  }, [xinglet, name, host]);

  return <XingletComponent {...props} />;
};
