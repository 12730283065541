import type { RuntimeConfig } from '@xing-com/crate-xinglet';

import type { FetchFunction, FetchImpl } from '../types';

export function configureFetchWithAcceptLanguageHeader(
  { fetch, Request }: FetchImpl,
  runtimeConfig: RuntimeConfig
): FetchFunction {
  return (...args) => {
    const { language } = runtimeConfig;
    const request = new Request(...args);
    request.headers.set('Accept-Language', language);

    return fetch(request);
  };
}
