import { datadogRum } from '@datadog/browser-rum';
import type { RumInitConfiguration } from '@datadog/browser-rum';

import type { ExceptionCapturer } from '@xing-com/crate-xinglet';

export function setupDataDog({
  applicationId,
  clientToken,
  sessionSampleRate,
  version,
  ...options
}: Partial<RumInitConfiguration> = {}): ExceptionCapturer {
  const env = {
    'www.xing.com': 'production' as const,
    'preview.xing.com': 'preview' as const,
  }[document.location.hostname];

  if (!env || !applicationId || !clientToken) {
    return (error, context) => {
      console.error(...[error, context].filter(Boolean));
    };
  }

  datadogRum.init({
    allowedTracingUrls: [
      /^https:\/\/.*?\.xing\.com/,
      /^https:\/\/.*?\.nwse.io/,
    ],
    applicationId,
    clientToken,
    defaultPrivacyLevel: 'mask',
    env,
    service: 'crate',
    sessionReplaySampleRate: 0,
    sessionSampleRate,
    site: 'datadoghq.eu',
    version,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    ...options,
  });

  datadogRum.setGlobalContextProperty('cost_center', '11131');
  datadogRum.setGlobalContextProperty('application', 'crate');

  return (error, context) => {
    datadogRum.addError(error, context);
  };
}
