import type {
  ComponentType,
  Context,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { createContext, useContext } from 'react';

import { hasKey } from '@xing-com/crate-object-helper';
import type {
  CommonHost,
  Host,
  InternalCommonHost,
  InternalHost,
} from '@xing-com/crate-xinglet';

export interface ContextHelper {
  consumeContext: InternalHost['consumeContext'];
  provideContext: (
    host: InternalCommonHost,
    xingletName: string
  ) => ReturnType<InternalHost['provideContext']>;
  ContextProvider: ComponentType<
    PropsWithChildren<{ name: string; host: Host }>
  >;
}

type ContextContainer = { context: Context<unknown>; value: unknown };

function getContexts(host: CommonHost, xingletName: string): string[] {
  return host
    .getXingletMetadata()
    .filter((metadata) => metadata.name === xingletName)
    .flatMap((metadata) =>
      hasKey('contexts', metadata.contributes) &&
      Array.isArray(metadata.contributes.contexts)
        ? metadata.contributes.contexts
        : []
    )
    .filter((context) => typeof context === 'string');
}

export function createContextHelper(): ContextHelper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const publishedContexts = new Map<string, ContextContainer>();

  return {
    /**
     * This function is used to store provided contexts and their value
     * under a given context-id.
     *
     * It should throw if the given context-id is not announced in the
     * xinglets metadata.
     */
    provideContext:
      (host, xingletName) => (contextId: string, contextValue: unknown) => {
        const isKnownContext = getContexts(host, xingletName).some(
          (declaredContext) => declaredContext === contextId
        );

        if (!isKnownContext) {
          throw new Error(
            `Unknown context '${contextId}'. Missed to add to your xinglet.json?`
          );
        }

        const context = createContext(contextValue);
        context.displayName = contextId;

        publishedContexts.set(contextId, { context, value: contextValue });
      },

    /**
     * This function will take a context-id and return the stored context
     * value for that. It must be used as a react custom hook.
     */
    consumeContext<T>(contextId: string): T {
      const container = publishedContexts.get(contextId);
      if (!container) {
        throw new Error(`Unknown context '${contextId}'`);
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/consistent-type-assertions
      return useContext(container.context) as T;
    },

    /**
     * This function is a react component wrapping it's children in
     * all known published contexts for for the given xinglet.
     */
    ContextProvider: ({
      name: xingletName,
      host,
      children,
    }: {
      name: string;
      host: Host;
      children?: ReactNode;
    }) => {
      const contexts = getContexts(host, xingletName)
        .map((contextId) => publishedContexts.get(contextId))
        .filter((container) => container !== undefined);

      return contexts.reduce(
        (children, container) => {
          return (
            <container.context.Provider value={container.value}>
              {children}
            </container.context.Provider>
          );
        },
        <>{children}</>
      );
    },
  };
}
