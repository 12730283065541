import type React from 'react';

import type { ManifestMap } from '@xing-com/crate-core-assets';
import { EventEmitter, Eventual } from '@xing-com/crate-xinglet';
import type {
  InternalHost,
  Runtime,
  RuntimeEvents,
  Xinglet,
  XingletComponent,
} from '@xing-com/crate-xinglet';

import { createXinglet } from './create-xinglet';
import { XingletContainer } from './xinglet-container';

export function createRuntime(
  manifest: ManifestMap,
  {
    autoRerender = false,
    languageData = {},
    xingletCache = new Map(),
    componentCache = new Map(),
  }: {
    autoRerender?: boolean;
    languageData?: Record<string, string>;
    xingletCache?: Map<string, Eventual<Xinglet>>;
    componentCache?: Map<string, Eventual<XingletComponent>>;
  } = {}
): Runtime {
  const events = new EventEmitter<RuntimeEvents>();
  const emptyComponent: React.ComponentType<unknown> = () => null;

  const runtime: Runtime = {
    autoRerender,
    events,
    languageData,
    manifest,
    metadata: Object.fromEntries(
      Object.entries(manifest).map(([name, manifest]) => {
        return [
          name,
          {
            ...manifest.metadata,
            id: manifest.id,
            name,
          },
        ];
      })
    ),
    xingletCache,
    componentCache,
    loadXinglet(host: InternalHost, name: string): Eventual<Xinglet> {
      let eventualXinglet = xingletCache.get(name);

      eventualXinglet ??= host.importXinglet(name).then((xingletClass) => {
        return createXinglet(host, name, xingletClass);
      });

      xingletCache.set(name, eventualXinglet);

      return eventualXinglet;
    },
    loadXingletComponent(
      host: InternalHost,
      name: string
    ): Eventual<XingletComponent> {
      let eventualComponent = componentCache.get(name);

      if (!eventualComponent) {
        eventualComponent = runtime.loadXinglet(host, name).then((xinglet) => {
          return Eventual.resolve(xinglet.getComponent?.(host)).then(
            (component = emptyComponent) => {
              component.displayName = `${name}(Xinglet)`;

              return (props) => {
                return (
                  <XingletContainer
                    {...props}
                    {...{
                      name,
                      host,
                      component,
                      xinglet,
                    }}
                  />
                );
              };
            }
          );
        });

        componentCache.set(name, eventualComponent);
      }

      return eventualComponent;
    },
  };

  return runtime;
}
