import { useState } from 'react';

import type { Eventual } from '../eventual';
import type { InternalHost } from '../host';
import type { Xinglet } from '../xinglet';
import { useHost } from './use-host';

export type AsyncXinglet =
  | { loading: true; xinglet: undefined }
  | { loading: false; xinglet: Xinglet };

function asyncXinglet(eventual: Eventual<Xinglet>): AsyncXinglet {
  return eventual.value
    ? { loading: false, xinglet: eventual.value }
    : { loading: true, xinglet: undefined };
}

export function useXinglet(name: string): AsyncXinglet {
  const host = useHost<InternalHost>();
  const eventualXinglet = host.runtime.loadXinglet(host, name);
  const [result, setResult] = useState(asyncXinglet(eventualXinglet));

  if (!result.xinglet) {
    host.runtime.loadXingletComponent(host, name).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setResult({ loading: false, xinglet: eventualXinglet.value! });
    });
  }

  return result;
}

export function useXinglets(names: string[]): { loading: boolean } {
  const host = useHost<InternalHost>();

  const eventualXinglets = names.map((name) => {
    return host.runtime.loadXinglet(host, name);
  });

  const [ready, setReady] = useState(
    eventualXinglets.every((eventual) => eventual.settled)
  );

  if (!ready) {
    Promise.all(eventualXinglets).then(() => {
      setReady(true);
    });
  }

  return { loading: !ready };
}
