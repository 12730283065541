import type { ManifestMap } from '@xing-com/crate-core-assets';

type ContributesRoutes = {
  route?: string;
  routes?: string[];
};

export function extractRoutes(manifestMap: ManifestMap): string[] {
  const routes = Object.values(manifestMap)
    .flatMap((manifest) => {
      const { route, routes = [] }: ContributesRoutes =
        manifest.metadata.contributes ?? {};

      return [...(route ? [route] : []), ...routes];
    })
    .map((route) => {
      return route.startsWith('/') ? route : `/${route}`;
    })
    .map((route) => {
      return route.endsWith('/*') ? route.replace(/\/\*$/, '/(.*)?') : route;
    });

  return [...routes, '/(.*)'];
}
