import { InternalXingletLoader } from '@xing-com/crate-runtime';
import type { InternalHost, XingletLoaderProps } from '@xing-com/crate-xinglet';

import type { XingletErrorBoundaryProps } from './xinglet-error-boundary';
import { XingletErrorBoundary } from './xinglet-error-boundary';

type BrowserXingletLoaderProps = XingletLoaderProps &
  XingletErrorBoundaryProps & { host: InternalHost };

export function BrowserXingletLoader({
  name,
  host,
  error: errorElement,
  ...otherProps
}: BrowserXingletLoaderProps): JSX.Element {
  return (
    <XingletErrorBoundary name={name} error={errorElement}>
      <InternalXingletLoader {...otherProps} name={name} host={host} />
    </XingletErrorBoundary>
  );
}
