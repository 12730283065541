import { joinUrl } from '@xing-com/crate-core-assets';
import type { ManifestMap } from '@xing-com/crate-core-assets';
import type { RuntimeConfig } from '@xing-com/crate-xinglet';

export async function loadManifest({
  assetsBaseUrl,
  manifestId,
}: RuntimeConfig): Promise<ManifestMap> {
  const manifestUrl = joinUrl(
    assetsBaseUrl,
    'runtime',
    `manifest-${manifestId}.json`
  );

  return await fetch(manifestUrl).then((result) => {
    return result.json();
  });
}
