import './public-path';

import ReactDOM from 'react-dom';

import { registerLogjamTracking } from '@xing-com/crate-core-fetch/src/browser/logjam';
import {
  createEntryPoint,
  extractRoutes,
  registerExternals,
} from '@xing-com/crate-runtime';
import type { GlobalScope, InternalBrowserHost } from '@xing-com/crate-xinglet';

import { createBrowserHost } from './create-browser-host';
import { setupDataDog } from './datadog';
import { loadManifest } from './load-manifest';

declare const globalThis: GlobalScope & {
  crateHost: InternalBrowserHost;
  document: typeof document;
};

(async () => {
  registerExternals(globalThis);

  const {
    startXinglets = [],
    dataDog: dataDogOptions,
    serverData = {},
    ...config
  } = globalThis.crate;
  const { basePath, enableMocks = false, entryPoint, manifestId } = config;

  const captureException = setupDataDog(dataDogOptions);

  const manifest = await loadManifest(config);
  const host = createBrowserHost(
    manifest,
    config,
    serverData,
    captureException
  );
  const { runtime } = host;

  registerLogjamTracking(config, extractRoutes(manifest));

  // NOTE: We store the host on the global scope for debugging purposes
  globalThis.crateHost = host;

  if (enableMocks) {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax
    const { setupMocking } = await import('./mocks');
    await setupMocking(config.mockSessionId);
  }

  // [SSR] if we have startXinglets to load, we do trigger this
  // this is required, since react needs to have all code available to
  // hydrate the server DOM
  await Promise.all(
    [entryPoint, ...startXinglets].map(async (name) => {
      await runtime.loadXingletComponent(host, name);
    })
  );

  const mountpoint = globalThis.document.querySelector('#app[data-mountpoint]');
  if (!mountpoint) {
    throw new Error('No mountpoint found');
  }

  const loader = (
    <div dangerouslySetInnerHTML={{ __html: mountpoint.innerHTML }} />
  );
  const app = createEntryPoint(host, entryPoint, {
    basePath,
    fallback: loader,
  });

  ReactDOM.hydrate(app, mountpoint);
  mountpoint.setAttribute('data-mounted', '');

  if (manifestId === 'dev') {
    const { startDevMode } = await import('./dev-mode');

    startDevMode(host);
  }
})().catch((error) => {
  console.error(error);
  const pre = document.createElement('pre');
  const text = document.createTextNode(error.stack ?? error);
  pre.appendChild(text);
  document.getElementById('app')?.replaceWith(pre);
});
