import devNote from '@xing-com/dev-note';

import { useHost } from './use-host';

type UseStatusCodeOptions = {
  /**
   * If true, the status code will be set. Otherwise nothing will happen.
   * This is useful because hooks cannot be conditionally called.
   * @default true
   */
  isActive?: boolean;
};

const useStatusCode = (code: number, { isActive = true } = {}): void => {
  const host = useHost();

  if (!isActive) return;

  if (host.isServer) {
    host.setResponseStatusCode(code);
  } else {
    devNote.warn('Status code can only be set on the server');
  }
};

export const useUnauthorizedStatusCode = (
  options?: UseStatusCodeOptions
): void => {
  useStatusCode(401, options);
};

export const useNotFoundStatusCode = (options?: UseStatusCodeOptions): void => {
  useStatusCode(404, options);
};

export const useGoneStatusCode = (options?: UseStatusCodeOptions): void => {
  useStatusCode(410, options);
};

export const useInternalServerErrorStatusCode = (
  options?: UseStatusCodeOptions
): void => {
  useStatusCode(500, options);
};
