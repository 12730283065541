import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import type { InternalCommonHost, RuntimeHost } from '../host';
import type { RuntimeConfig } from '../runtime-config';

const hostContext = createContext<RuntimeHost | undefined>(undefined);
hostContext.displayName = 'host';

/**
 * @deprecated Do not use an explicit HostProvider anymore. The host is provided by default
 */
export const HostProvider: FC<PropsWithChildren<{ host: RuntimeHost }>> = ({
  host,
  children,
}) => <hostContext.Provider value={host}>{children}</hostContext.Provider>;

export function useHost<T = unknown>(): RuntimeHost<T> {
  const host = useContext(hostContext);

  if (!host) {
    throw new Error('No host context found. Please setup the HostProvider');
  }

  return host;
}

/**
 * @deprecated Do not use, this is an internal API and may change at any time.
 */
export function useRuntimeConfig(): RuntimeConfig {
  return useHost<InternalCommonHost>().config;
}

function useCrateContext<T>(contextId: string): T {
  return useHost().consumeContext<T>(contextId);
}

export { useCrateContext as useContext };
