import type { CrateImports, CrateModule } from '@xing-com/crate-xinglet';

async function sleep(ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export async function loadExternals(
  crateImports: CrateImports,
  dependencies: string[]
): Promise<CrateModule[]> {
  return await Promise.all(
    dependencies.map(async (dependency) => {
      const entry = crateImports[dependency];

      if (!entry) {
        throw new Error(
          `State error: '${dependency} is not a registered external`
        );
      } else if (typeof entry !== 'function') {
        // NOTE: If it's not a function it means we've already loaded it.
        return entry;
      }

      const result = await entry().catch(async () => {
        // back-off for a short amount and then retry
        await sleep(250);
        return await entry().catch((error: unknown) => {
          throw new Error(
            `Failed to load dependency '${dependency}'; Cause: ${
              error instanceof Error ? error.message : error
            }`
          );
        });
      });

      return (crateImports[dependency] = result);
    })
  );
}
